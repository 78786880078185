import { ErrorMessage } from '@hookform/error-message'
import {
  IonLabel,
  IonInput,
  IonButton,
  IonContent,
  IonLoading,
  IonToolbar,
  IonTitle,
  IonHeader,
  IonRippleEffect,
  IonAlert,
} from '@ionic/react'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { AuthState, resetPassword } from '../../store/redux/slices/authSlice'
import { AppState } from '../../store/redux/types'
import { useNativeKeyboard } from '../../hooks/useNativeKeyboard'

type ResetPasswordFormProps = {
  onClose: () => void
}

interface ResetPasswordFormInputs {
  email: string
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onClose }) => {
  const { isLoading } = useSelector((state: AppState): AuthState => state.auth)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const handleNativeKeyboard = useNativeKeyboard()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPasswordFormInputs>()

  const submitHandler = async (data: ResetPasswordFormInputs) => {
    const email: string = data.email
    const resultAction = await dispatch(resetPassword(email))
    if (resetPassword.fulfilled.match(resultAction)) {
      setIsSubmitted(true)
      setShowAlert(true)
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle id="modalTitle" size="large">
            {t('login.resetPassword')}
          </IonTitle>
          <IonButton slot="end" fill="clear" onClick={() => onClose()}>
            {t('btn.close')}
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonLoading isOpen={isLoading} message={t('loading.sending')} spinner="crescent" translucent={true} />
        <p>{t('login.resetText')}</p>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ field: { onChange } }) => (
              <IonInput
                label={t('forms.emailAddress')}
                labelPlacement="stacked"
                fill="outline"
                type="email"
                onIonChange={onChange}
                onIonFocus={handleNativeKeyboard}
                placeholder="example@mail.com"
                enterkeyhint="send"
                required={true}
                inputMode="email"
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                message: t('forms.invalidEmail'),
              },
            }}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
          />
          {isSubmitted ? (
            <IonButton
              className="ion-margin-top ion-activatable ripple-parent"
              color="success"
              disabled
              expand="block"
              type="submit"
            >
              {t('login.codeSent')}
              <IonRippleEffect />
            </IonButton>
          ) : (
            <IonButton className="ion-margin-top" expand="block" type="submit">
              {t('btn.reset')}
            </IonButton>
          )}
        </form>
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false)
          onClose()
        }}
        header={t('login.passwordResetSuccess')}
        message={t('login.codeSent')}
        buttons={['OK']}
      />
    </>
  )
}

export default ResetPasswordForm
