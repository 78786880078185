// src/store/redux/slices/configSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Config } from '../../../types/interfaces'
import { AppState } from '../types'
import { WritableDraft } from 'immer'
import { getRegionCode } from '../utils/getRegionCode'
import { defaultConfig } from '../../../constants/defaults'
import { ConfigAPI } from '../../../api/ConfigAPI'

export interface ConfigState {
  config: Config
  isLoading: boolean
  error: string | null
}

export const initialState: ConfigState = {
  config: defaultConfig,
  isLoading: false,
  error: null,
}

export const fetchConfig = createAsyncThunk<Config, void>(
  'news/fetchConfig',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const configAPI: ConfigAPI = new ConfigAPI(regionCode)
    try {
      return await configAPI.getConfig()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.pending, (state: WritableDraft<ConfigState>) => {
        state.isLoading = true
      })
      .addCase(fetchConfig.fulfilled, (state: WritableDraft<ConfigState>, action: PayloadAction<Config>) => {
        state.config = action.payload
        state.isLoading = false
      })
      .addCase(fetchConfig.rejected, (state: WritableDraft<ConfigState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
  },
})

export default configSlice.reducer
