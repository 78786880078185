// src/api/ShiftAPI.ts

import { API_PATH } from '../constants/defaults'
import { BaseAPI } from './BaseAPI'
import { ShiftFilter, ShiftProjectLeaders, VolunteerShiftHourStatus } from '../types/types'
import { RegisterToShiftAPIResponse, Shift } from '../types/interfaces'

interface updateVolunteerShiftHourStatusData {
  Status: VolunteerShiftHourStatus
}

interface updateVolunteerShiftAnnouncementData {
  Remark: string
}

/**
 * ShiftAPI class
 * @classdesc Class for interacting with the shift API
 */
export class ShiftAPI extends BaseAPI {
  /**
   * Get the shifts for the current user
   * @param {ShiftFilter} filter - The filter for the shifts
   * @returns {Promise<Shift[]>} - The shifts for the current user
   */
  public async getShifts(filter: ShiftFilter = 'registered'): Promise<Shift[]> {
    return this.api.get(`${API_PATH.SHIFTS}?filter=${filter}`).then((response) => response.data)
  }

  /**
   * Get a shift by ID
   * @param shiftId {string} - The shift ID
   * @returns {Promise<Shift>} - The shift
   */
  public async getShift(shiftId: string): Promise<Shift> {
    return this.api.get(`${API_PATH.SHIFTS}/${shiftId}`).then((response) => response.data[0])
  }

  /**
   * Get the project leaders for a shift
   * @param shiftId {string} - The shift ID
   * @returns {Promise<ShiftProjectLeaders>} - The project leaders for the shift
   */
  public async getShiftProjectLeaders(shiftId: string): Promise<ShiftProjectLeaders> {
    return this.api.get(`${API_PATH.SHIFTS}/${shiftId}/pls`).then((response) => response.data)
  }

  /**
   * Update the status of a volunteer shift hour
   * @param shiftId {string} - The shift ID
   * @param hourId {string} - The hour ID
   * @param status {VolunteerShiftHourStatus} - The status to update
   * @returns {Promise<string>} - The response from the API
   */
  public async updateVolunteerShiftHourStatus(
    shiftId: string,
    hourId: string,
    status: VolunteerShiftHourStatus,
  ): Promise<string> {
    const data: updateVolunteerShiftHourStatusData = {
      Status: status,
    }
    return this.api
      .patch(`${API_PATH.PROJECT_LEADER_SHIFTS}/${shiftId}/${hourId}`, data)
      .then((response) => response.data)
  }

  /**
   * Update the announcement of a volunteer shift
   * @param shiftId {string} - The shift ID
   * @param announcement {string} - The announcement to update
   * @returns {Promise<string>} - The response from the API
   */
  public async updateVolunteerShiftAnnouncement(shiftId: string, announcement: string): Promise<string> {
    const data: updateVolunteerShiftAnnouncementData = {
      Remark: announcement,
    }
    return this.api.patch(`${API_PATH.PROJECT_LEADER_SHIFTS}/${shiftId}`, data).then((response) => response.data)
  }

  /**
   * Register to a shift
   * @param shiftId {string} - The shift ID
   * @returns {Promise<RegisterToShiftAPIResponse>} - The response from the API
   */
  public async registerToShift(shiftId: string): Promise<RegisterToShiftAPIResponse> {
    return this.api.post(`${API_PATH.SHIFTS}/${shiftId}`).then((response) => response.data)
  }

  /**
   * Unregister from a shift
   * @param shiftId {string} - The shift ID
   * @returns {Promise<string>} - The response from the API
   */
  public async unregisterFromShift(shiftId: string): Promise<string> {
    return this.api.delete(`${API_PATH.SHIFTS}/${shiftId}`).then((response) => response.data)
  }
}
