// src/containers/LoginPage/WelcomeSlide.tsx

import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { Capacitor } from '@capacitor/core'
import { arrowForward } from 'ionicons/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Swiper from 'swiper'

interface WelcomeSlideProps {
  swiperInstance: Swiper
  scrollToTop: () => void
}

const WelcomeSlide: React.FC<WelcomeSlideProps> = ({ swiperInstance, scrollToTop }) => {
  const { t } = useTranslation()

  return (
    <IonGrid className="loginScreen__region">
      <IonRow className="fullwidth">
        <IonCol size-sm="8" offset-sm="2" size-md="6" offset-md="3" size-lg="4" offset-lg="4">
          {Capacitor.getPlatform() === 'ios' && <div>&nbsp;</div>}
          <h2 aria-label={t('login.titleFull')}>{t('login.title')}</h2>
          {document.body.classList.contains('dark') ? (
            <img src="/assets/images/servenow-dark.svg" className="logo" alt="" />
          ) : (
            <img src="/assets/images/servenow-light.svg" className="logo" alt="" />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <img src="/assets/images/welcome.svg" alt="" className="login" />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <p>{t('login.welcome')}</p>
          <p>{t('login.welcomeSub')}</p>
        </IonCol>
      </IonRow>
      <IonRow className="footer fullwidth">
        <IonCol className="ion-text-right" size="6" offset="6">
          <IonButton
            fill="clear"
            onClick={() => {
              swiperInstance!.slideNext()
              scrollToTop()
            }}
          >
            {t('btn.continue')} <IonIcon icon={arrowForward} slot="end" aria-hidden={true}></IonIcon>
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default WelcomeSlide
