// src/utils/preferencesStorage.ts

import { GetResult, Preferences } from '@capacitor/preferences'

/**
 * Set a preference value
 * @param key {string} The key of the preference
 * @param value The value of the preference
 * @returns {Promise<void>}
 */
const setPreference = async <T>(key: string, value: T): Promise<void> => {
  try {
    await Preferences.set({
      key,
      value: JSON.stringify(value),
    })
  } catch (error) {
    console.error(`Error setting value for key "${key}":`, error)
  }
}

/**
 * Get a preference value
 * @param key {string} The key of the preference
 * @returns The value of the preference
 */
const getPreference = async <T>(key: string): Promise<T | null> => {
  try {
    const item: GetResult = await Preferences.get({ key })
    if (item?.value) {
      return JSON.parse(item.value) as T
    }
    return null
  } catch (error) {
    console.error(`Error getting value for key "${key}":`, error)
    return null
  }
}

/**
 * Clear all preferences.
 */
const clearPreferences = async (): Promise<void> => {
  try {
    await Preferences.clear()
  } catch (error) {
    console.error('Error clearing preferences:', error)
  }
}

export { setPreference, getPreference, clearPreferences }
