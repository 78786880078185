// src/store/redux/slices/authSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { DataType, SecureStorage } from '@aparajita/capacitor-secure-storage'
import { AppState } from '../types'
import { AuthAPI } from '../../../api/AuthAPI'
import { ProfileAPI } from '../../../api/ProfileAPI'
import { User } from '../../../types/interfaces'
import { WritableDraft } from 'immer'
import { getRegionCode } from '../utils/getRegionCode'
import { PURGE } from 'redux-persist/es/constants'
import { RegionApiCodes } from '../../../types/types'
import { clearPreferences } from '../../../utils/preferencesStorage'

export interface AuthState {
  user: User | null
  token: string | null
  isAuth: boolean
  isLoading: boolean
  error: number | string | null
}

export const initialState: AuthState = {
  user: null,
  token: null,
  isAuth: false,
  isLoading: false,
  error: null,
}

interface LoginPayload {
  email: string
  password: string
}

interface SignUpPayload {
  firstname: string
  lastname: string
  email: string
  password: string
}

interface ActivatePayload {
  regionApiCode: RegionApiCodes
  email: string
  token: string
}

interface UpdateEmailPayload {
  regionApiCode: RegionApiCodes
  id: string
  oldEmail: string
  newEmail: string
}

interface UpdatePasswordPayload {
  regionApiCode: RegionApiCodes
  email: string
  token: string
  password: string
}

export const login = createAsyncThunk<User, LoginPayload>(
  'auth/login',
  async (payload: LoginPayload, { getState, rejectWithValue }) => {
    const { email, password } = payload
    try {
      const regionCode: string = getRegionCode(getState() as AppState)
      const authAPI: AuthAPI = new AuthAPI(regionCode)
      const response: User = await authAPI.login(email, password)

      await SecureStorage.set('serveNowAuth', response.JWT)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const initialiseUser = createAsyncThunk<User, void>(
  'auth/initialise',
  async (_, { getState, rejectWithValue }) => {
    try {
      const token: DataType | null = await SecureStorage.get('serveNowAuth')
      if (!token) {
        const state = getState() as AppState
        // Reject either if the user is authenticated or not
        const errorMessage: string = state.auth.isAuth ? 'No token found' : 'Not authenticated'
        return rejectWithValue(errorMessage)
      }
      const regionCode: string = getRegionCode(getState() as AppState)
      const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
      return await profileAPI.getProfile()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const signUp = createAsyncThunk<undefined, SignUpPayload>(
  'auth/signUp',
  async (signUpData: SignUpPayload, { getState, rejectWithValue }) => {
    const { firstname, lastname, email, password } = signUpData
    try {
      const regionCode: string = getRegionCode(getState() as AppState)
      const authAPI: AuthAPI = new AuthAPI(regionCode)
      await authAPI.signUp(firstname, lastname, email, password)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const activateAccount = createAsyncThunk<string, ActivatePayload>(
  'auth/activateAccount',
  async ({ regionApiCode, email, token }, { rejectWithValue }) => {
    try {
      const authAPI: AuthAPI = new AuthAPI(regionApiCode)
      return await authAPI.activateAccount(email, token)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const logout = createAsyncThunk<void, void>('auth/logout', async (_, { dispatch }) => {
  await SecureStorage.clear()
  await clearPreferences()
  dispatch(authSlice.actions.resetUser())
})

export const resetPassword = createAsyncThunk<undefined, string>(
  'auth/resetPassword',
  async (email: string, { dispatch, getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const authAPI: AuthAPI = new AuthAPI(regionCode)

    try {
      await authAPI.resetPassword(email)
      dispatch(authSlice.actions.resetUser())
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateEmail = createAsyncThunk<string, UpdateEmailPayload>(
  'auth/updateEmail',
  async ({ regionApiCode, id, oldEmail, newEmail }, { rejectWithValue }) => {
    try {
      const authAPI: AuthAPI = new AuthAPI(regionApiCode)
      return await authAPI.updateEmail(id, oldEmail, newEmail)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const validateToken = createAsyncThunk<string, ActivatePayload>(
  'auth/validateToken',
  async ({ regionApiCode, email, token }, { rejectWithValue }) => {
    try {
      const authAPI: AuthAPI = new AuthAPI(regionApiCode)
      return await authAPI.validateToken(email, token)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updatePassword = createAsyncThunk<string, UpdatePasswordPayload>(
  'auth/updatePassword',
  async ({ regionApiCode, email, token, password }, { rejectWithValue }) => {
    try {
      const authAPI: AuthAPI = new AuthAPI(regionApiCode)
      return await authAPI.updatePassword(email, token, password)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Set the user state. This is made for reusability
 * @param state {WritableDraft<AuthState>} - The state to be updated
 * @param action {PayloadAction<User>} - The action to be dispatched
 */
const setUserState = (state: WritableDraft<AuthState>, action: PayloadAction<User>) => {
  state.user = action.payload
  state.token = action.payload.JWT
  state.isAuth = true
  state.isLoading = false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetUser(state) {
      state.user = null
      state.token = null
      state.isAuth = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(login.pending, (state: WritableDraft<AuthState>) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, setUserState)
      .addCase(login.rejected, (state: WritableDraft<AuthState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(initialiseUser.fulfilled, setUserState)
      .addCase(signUp.pending, (state: WritableDraft<AuthState>) => {
        state.isLoading = true
      })
      .addCase(signUp.fulfilled, (state: WritableDraft<AuthState>) => {
        // resetUser is called from the logout action directly
        state.isLoading = false
      })
      .addCase(signUp.rejected, (state: WritableDraft<AuthState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(activateAccount.pending, (state: WritableDraft<AuthState>) => {
        state.isLoading = true
      })
      .addCase(activateAccount.fulfilled, (state: WritableDraft<AuthState>) => {
        state.isLoading = false
      })
      .addCase(activateAccount.rejected, (state: WritableDraft<AuthState>, action) => {
        state.isLoading = false
        state.error = (action.error.message as string) || null
      })
      .addCase(updateEmail.pending, (state: WritableDraft<AuthState>) => {
        state.isLoading = true
      })
      .addCase(updateEmail.fulfilled, (state: WritableDraft<AuthState>) => {
        state.isLoading = false
      })
      .addCase(updateEmail.rejected, (state: WritableDraft<AuthState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(updatePassword.pending, (state: WritableDraft<AuthState>) => {
        state.isLoading = true
      })
      .addCase(updatePassword.fulfilled, (state: WritableDraft<AuthState>) => {
        state.isLoading = false
      })
      .addCase(updatePassword.rejected, (state: WritableDraft<AuthState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(validateToken.pending, (state: WritableDraft<AuthState>) => {
        state.isLoading = true
      })
      .addCase(validateToken.fulfilled, (state: WritableDraft<AuthState>) => {
        state.isLoading = false
      })
      .addCase(validateToken.rejected, (state: WritableDraft<AuthState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(resetPassword.pending, (state: WritableDraft<AuthState>) => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state: WritableDraft<AuthState>) => {
        // resetUser is called from the resetPassword action directly
        state.isLoading = false
      })
      .addCase(resetPassword.rejected, (state: WritableDraft<AuthState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
  },
})

export default authSlice.reducer
