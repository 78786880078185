import { IonRouterOutlet } from '@ionic/react'
import { Redirect, Route } from 'react-router'
import { IonReactRouter } from '@ionic/react-router'
import React, { useEffect } from 'react'

import WelcomePage from '../welcome/WelcomePage'
import useStatusBarStyle from '../../hooks/useStatusBarStyle'

const UnauthenticatedApp: React.FC = () => {
  const setStatusBarStyle = useStatusBarStyle()
  useEffect(() => {
    setStatusBarStyle()
  }, [setStatusBarStyle])

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/login" component={WelcomePage} />
        <Route render={() => <Redirect to="/login" />} />
      </IonRouterOutlet>
    </IonReactRouter>
  )
}

export default UnauthenticatedApp
