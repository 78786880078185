// src/api/ApplicationAPI.ts

import { API_PATH } from '../constants/defaults'
import { BaseAPI } from './BaseAPI'
import { Application, ApplicationData } from '../types/interfaces'
import { ApplicationUpdateAction } from '../types/types'

interface FetchProjectApplicationsPayload {
  archive?: boolean
  limit?: number
  offset?: number
}

/**
 * ApplicationAPI class
 * @classdesc Class for interacting with the Application API
 */
export class ApplicationAPI extends BaseAPI {
  /**
   * Get the applications
   * @returns {Promise<Application[]>} - The applications
   */
  public async getApplications(): Promise<Application[]> {
    return this.api.get(API_PATH.PROJECT_LEADER_APPLICATIONS).then((response) => response.data)
  }

  /**
   * Get the applications for a specific project
   * @param projectId {string} - The project ID
   * @param archive {boolean} - Whether to fetch archived applications
   * @param limit {number | undefined} - The limit of applications to fetch
   * @param offset {number | undefined} - The offset of applications to fetch
   * @returns {Promise<Application[]>} - The applications
   */
  public async getProjectApplications(
    projectId: string,
    archive: boolean = false,
    limit: number | undefined,
    offset: number | undefined,
  ): Promise<Application[]> {
    const params: FetchProjectApplicationsPayload = { archive }
    if (limit) {
      params.limit = limit
    }
    if (offset) {
      params.offset = offset
    }
    return this.api
      .get(`${API_PATH.PROJECT_LEADER_APPLICATIONS}/${projectId}`, { params })
      .then((response) => response.data)
  }

  /**
   * Get the application
   * @param applicationId {string} - The application ID
   * @returns {Promise<Application>} - The application
   */
  public async getApplication(applicationId: string): Promise<Application> {
    return this.api
      .get(`${API_PATH.PROJECT_LEADER_APPLICATIONS}/application/${applicationId}`)
      .then((response) => response.data[0])
  }

  /**
   * Update the application
   * @param applicationId {string} - The application ID
   * @param data {ApplicationData} - The application data
   * @returns {Promise<string>} - The success message
   */
  public async updateApplication(applicationId: string, data: ApplicationData): Promise<string> {
    return this.api
      .patch(`${API_PATH.PROJECT_LEADER_APPLICATIONS}/${applicationId}/update`, data)
      .then((response) => response.data)
  }

  /**
   * Update the application based on a specific action
   * @param applicationId {string} - The application ID
   * @param action {ApplicationUpdateAction} - The action to perform
   * @returns {Promise<string>} - The success message
   */
  public async updateApplicationAction(applicationId: string, action: ApplicationUpdateAction): Promise<string> {
    return this.api
      .patch(`${API_PATH.PROJECT_LEADER_APPLICATIONS}/${applicationId}/${action}`)
      .then((response) => response.data)
  }
}
