import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { AppState } from '../../store/redux/types'
import { activateAccount, AuthState } from '../../store/redux/slices/authSlice'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RegionApiCodes } from '../../types/types'
import FooterCommon from '../../components/FooterCommon'

interface ActivateAccountParams {
  regionApiCode: RegionApiCodes
  email: string
  token: string
}

interface Payload {
  response: {
    status: number
  }
}

const ActivateAccount: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector((state: AppState): AuthState => state.auth)
  const { regionApiCode, email, token } = useParams<ActivateAccountParams>()
  const [message, setMessage] = useState('')
  const { t } = useTranslation()

  const hasTriedToActivate = useRef(false)
  useEffect(() => {
    if (!hasTriedToActivate.current) {
      hasTriedToActivate.current = true
      dispatch(activateAccount({ regionApiCode, email, token })).then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          setMessage(t('activate.success'))
        } else {
          const payload: Payload = result.payload as Payload
          switch (payload.response.status) {
            case 400:
              setMessage(t('activate.notFound'))
              break
            case 409:
              setMessage(t('activate.alreadyActivated'))
              break
            default:
              setMessage(t('activate.error'))
              break
          }
        }
      })
    }
  }, [email, token, dispatch, t, regionApiCode])

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="text-center">
              <IonImg className="logo mx-auto ion-padding-vertical" src="/assets/images/servenow-light.svg" alt="" />
              <h1 className="ion-padding-vertical">{t('activate.title')}</h1>
              {isLoading ? <p>{t('loading.loading')}</p> : <p>{message}</p>}
            </IonCol>
          </IonRow>
        </IonGrid>
        <FooterCommon></FooterCommon>
      </IonContent>
    </IonPage>
  )
}

export default ActivateAccount
