// src/store/redux/slices/applicationSlice.ts

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../types'
import { ApplicationAPI } from '../../../api/ApplicationAPI'
import { Application, ApplicationData } from '../../../types/interfaces'
import { WritableDraft } from 'immer'
import { getRegionCode } from '../utils/getRegionCode'
import { ApplicationUpdateAction } from '../../../types/types'
import { fetchProjectsPL } from './projectSlice'

export interface ApplicationObject {
  [key: string]: Application
}

export interface ApplicationState {
  applications: ApplicationObject
  applicationsArchive: ApplicationObject
  updatingApplicationIds: string[]
  isLoading: boolean
  error: string | null
}

export const initialState: ApplicationState = {
  applications: {},
  applicationsArchive: {},
  updatingApplicationIds: [],
  isLoading: false,
  error: null,
}

interface FetchProjectApplicationsPayload {
  projectId: string
  archive?: boolean
  limit?: number | undefined
  offset?: number | undefined
}

interface FetchProjectApplicationsResponse {
  applications: ApplicationObject
  archive: boolean
}

interface UpdateApplicationPayload {
  applicationId: string
  data: ApplicationData
}

interface UpdateApplicationActionPayload {
  applicationId: string
  action: ApplicationUpdateAction
}

export const fetchApplications = createAsyncThunk<ApplicationObject, void>(
  'application/fetchApplications',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const applicationAPI: ApplicationAPI = new ApplicationAPI(regionCode)
    try {
      const applications: Application[] = await applicationAPI.getApplications()
      // Convert the applications array to an object for easier access and manipulation
      return applications.reduce((acc: ApplicationObject, application: Application) => {
        acc[application.ApplicationId] = application
        return acc
      }, {})
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchProjectApplications = createAsyncThunk<
  FetchProjectApplicationsResponse,
  FetchProjectApplicationsPayload
>(
  'application/fetchProjectApplications',
  async (data: FetchProjectApplicationsPayload, { getState, rejectWithValue }) => {
    const { projectId, archive: archiveTemp = false, limit, offset } = data
    const archive: boolean = archiveTemp
    const regionCode: string = getRegionCode(getState() as AppState)
    const applicationAPI: ApplicationAPI = new ApplicationAPI(regionCode)
    try {
      const applicationsTemp: Application[] = await applicationAPI.getProjectApplications(
        projectId,
        archive,
        limit,
        offset,
      )
      // Convert the applications array to an object for easier access and manipulation
      const applications = applicationsTemp.reduce((acc: ApplicationObject, application: Application) => {
        acc[application.ApplicationId] = application
        return acc
      }, {})
      return { applications, archive }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchApplication = createAsyncThunk<Application, string>(
  'application/fetchApplication',
  async (applicationId: string, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const applicationAPI: ApplicationAPI = new ApplicationAPI(regionCode)
    try {
      return await applicationAPI.getApplication(applicationId)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateApplication = createAsyncThunk<undefined, UpdateApplicationPayload>(
  'application/updateApplication',
  async (payload: UpdateApplicationPayload, { dispatch, getState, rejectWithValue }) => {
    const { applicationId, data } = payload
    const regionCode: string = getRegionCode(getState() as AppState)
    const applicationAPI: ApplicationAPI = new ApplicationAPI(regionCode)
    try {
      await applicationAPI.updateApplication(applicationId, data)
      dispatch(fetchApplications())
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateApplicationAction = createAsyncThunk<undefined, UpdateApplicationActionPayload>(
  'application/updateApplicationAction',
  async (payload: UpdateApplicationActionPayload, { dispatch, getState, rejectWithValue }) => {
    const { applicationId, action } = payload
    const regionCode: string = getRegionCode(getState() as AppState)
    const applicationAPI: ApplicationAPI = new ApplicationAPI(regionCode)
    try {
      await applicationAPI.updateApplicationAction(applicationId, action)
      dispatch(updateApplicationActionLocal(payload))
      if (action === 'read' || action === 'unread') {
        dispatch(fetchProjectsPL())
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateApplicationActionLocal(
      state: WritableDraft<ApplicationState>,
      action: PayloadAction<UpdateApplicationActionPayload>,
    ) {
      const { applicationId, action: updateAction } = action.payload
      const application: WritableDraft<Application> = state.applications[applicationId]
      if (application) {
        switch (updateAction) {
          case 'read':
            application.ApplicationRead = true
            break
          case 'unread':
            application.ApplicationRead = false
            break
          case 'archive':
            application.ApplicationArchived = true
            break
          case 'unarchive':
            application.ApplicationArchived = false
            break
          default:
            break
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplications.pending, (state: WritableDraft<ApplicationState>) => {
        state.isLoading = true
      })
      .addCase(
        fetchApplications.fulfilled,
        (state: WritableDraft<ApplicationState>, action: PayloadAction<ApplicationObject>) => {
          state.applications = action.payload
          state.isLoading = false
        },
      )
      .addCase(fetchApplications.rejected, (state: WritableDraft<ApplicationState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(fetchProjectApplications.pending, (state: WritableDraft<ApplicationState>) => {
        state.isLoading = true
      })
      .addCase(
        fetchProjectApplications.fulfilled,
        (state: WritableDraft<ApplicationState>, action: PayloadAction<FetchProjectApplicationsResponse>) => {
          if (action.payload.archive) {
            state.applicationsArchive = action.payload.applications
          } else {
            state.applications = action.payload.applications
          }
          state.isLoading = false
        },
      )
      .addCase(fetchProjectApplications.rejected, (state: WritableDraft<ApplicationState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(fetchApplication.pending, (state: WritableDraft<ApplicationState>) => {
        state.isLoading = true
      })
      .addCase(fetchApplication.fulfilled, (state: WritableDraft<ApplicationState>) => {
        state.isLoading = false
      })
      .addCase(fetchApplication.rejected, (state: WritableDraft<ApplicationState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(updateApplication.pending, (state: WritableDraft<ApplicationState>) => {
        state.isLoading = true
      })
      .addCase(updateApplication.fulfilled, (state: WritableDraft<ApplicationState>) => {
        state.isLoading = false
      })
      .addCase(updateApplication.rejected, (state: WritableDraft<ApplicationState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(updateApplicationAction.pending, (state: WritableDraft<ApplicationState>, action) => {
        state.updatingApplicationIds.push(action.meta.arg.applicationId)
      })
      .addCase(updateApplicationAction.fulfilled, (state: WritableDraft<ApplicationState>, action) => {
        state.updatingApplicationIds = state.updatingApplicationIds.filter((id) => id !== action.meta.arg.applicationId)
      })
      .addCase(updateApplicationAction.rejected, (state: WritableDraft<ApplicationState>, action) => {
        state.error = (action.error.message as string) || null
        state.updatingApplicationIds = state.updatingApplicationIds.filter((id) => id !== action.meta.arg.applicationId)
      })
  },
})

export const { updateApplicationActionLocal } = applicationSlice.actions

export default applicationSlice.reducer
