import React from 'react'
import { Trans } from 'react-i18next'

const AuthTermsAndConditions: React.FC = () => {
  return (
    <div className="ion-text-center">
      <span>
        <small>
          <Trans
            i18nKey="login.disclaimer"
            components={{
              1: <a href="https://www.servenow.app/privacy-policy">Privacy Policy</a>,
              2: <a href="https://www.servenow.app/terms-and-conditions">Terms and Conditions</a>,
            }}
          />
        </small>
      </span>
    </div>
  )
}

export default AuthTermsAndConditions
