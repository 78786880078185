// src/store/redux/slices/toastSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'

export interface ToastState {
  show: boolean
  message: string
  duration?: number
}

export const initialState: ToastState = {
  show: false,
  message: '',
  duration: 3000,
}

interface ToastPayload {
  message: string
  duration?: number
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state: WritableDraft<ToastState>, action: PayloadAction<ToastPayload>): void => {
      state.show = true
      state.message = action.payload.message
      if (action.payload.duration) {
        state.duration = action.payload.duration
      }
    },
    hideToast: (state: WritableDraft<ToastState>): void => {
      state.show = false
      state.message = ''
    },
  },
})

export const { showToast, hideToast } = toastSlice.actions
export default toastSlice.reducer
