// src/store/redux/slices/regionSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'
import { Config, Languages, Region } from '../../../types/interfaces'
import { AppState } from '../types'
import { LanguageCode } from '../../../types/types'
import { PURGE } from 'redux-persist/es/constants'
import { fetchConfig } from './configSlice'

export interface RegionState {
  name: string
  apiCode: string
  code: string
  timeZone: string
  isLoading: boolean
  languages: Languages
  selectedLanguage: LanguageCode | null
  error: string
}

export const initialState: RegionState = {
  name: '',
  apiCode: '',
  code: '',
  timeZone: '',
  isLoading: false,
  languages: {},
  selectedLanguage: null,
  error: '',
}

export const fetchLanguages = createAsyncThunk<Languages, void>(
  'region/fetchLanguages',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      await dispatch(fetchConfig())
      const config: Config = (getState() as AppState).config.config
      return config.languageLabels
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setRegion(state: WritableDraft<RegionState>, action: PayloadAction<Region>) {
      state.apiCode = action.payload.apiCode
      state.code = action.payload.code
      state.name = action.payload.name
      state.timeZone = action.payload.timeZone
    },
    setLanguage(state: WritableDraft<RegionState>, action: PayloadAction<LanguageCode | null>) {
      state.selectedLanguage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(fetchLanguages.pending, (state: WritableDraft<RegionState>) => {
        state.isLoading = true
      })
      .addCase(fetchLanguages.fulfilled, (state: WritableDraft<RegionState>, action: PayloadAction<Languages>) => {
        state.languages = action.payload
        state.isLoading = false
      })
      .addCase(fetchLanguages.rejected, (state: WritableDraft<RegionState>, action) => {
        state.error = (action.error.message as string) || ''
        state.isLoading = false
      })
  },
})

export const { setRegion, setLanguage } = regionSlice.actions
export default regionSlice.reducer
